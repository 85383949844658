<template>
  <div>
    <side-bar-menu :menu="$section().admin"></side-bar-menu>
    <idle-timer></idle-timer>
    <mobile-detected></mobile-detected>
    <b-row class="top-row border-bottom d-md-flex align-items-center">
      <b-col
        class="d-none d-md-block"
        cols="2"
        md="2"
      >
        <span class="custom-font-size-1rem">{{ qtt }} {{ $tc('building.buildings', qtt).toUpperCase() }}</span>
      </b-col>
      <b-col
        cols="8"
        md="6"
      >
        <el-input
          v-model="filterText"
          size="large"
          prefix-icon="el-icon-search"
          :placeholder="$t('components.typeToSearch')"
        />
      </b-col>
      <b-col
        cols="4"
        md="4"
      >
        <el-button
          type="primary"
          @click="segue('new', '', {})"
          class="float-right"
          v-b-modal="'modal-building'"
        >{{ $t('action.add') }}</el-button>
      </b-col>
    </b-row>

    <b-row>
      <b-col
        cols="12"
        md="12"
        lg="12"
        class="mb-4 pb-4 border-bottom bg-white"
      >

        <div class="mt-4 ml-4">

          <el-tree
            :props="props"
            :load="loadNode"
            ref="tree"
            node-key="uuid"
            :filter-node-method="filterNode"
            lazy
            accordion
            @node-click="handleNodeClick"
            v-loading="loading"
          >

            <span
              class="custom-tree-node"
              slot-scope="{ node, data }"
            >
              <div
                class="custom-tree-node"
                v-if="node.data.type == 'bdg'"
              >
                <div><span class="tree-item-building">{{ node.data.label }}</span> <span class="tree-item-building-ref">({{ node.data.ref }})</span></div>
                <span class="d-none d-sm-block">
                  <el-button
                    size="mini"
                    type="primary"
                    icon="el-icon-plus"
                    v-b-modal="'modal-level'"
                    @click="segue('new', node, data, $event)"
                    plain
                  ></el-button>
                  <el-button
                    size="mini"
                    type="info"
                    icon="el-icon-edit"
                    v-b-modal="'modal-building'"
                    @click="segue('edit', node, data, $event)"
                    plain
                  ></el-button>
                  <el-button
                    size="mini"
                    type="danger"
                    icon="el-icon-delete"
                    @click.stop="removeNode(node, data)"
                    plain
                  ></el-button>
                </span>
              </div>
              <div
                class="custom-tree-node"
                v-if="node.data.type == 'lvl'"
              >
                <span class="tree-item-level">{{ $t('building.level') }} {{ node.data.label }}</span>
                <span class="d-none d-sm-block">
                  <el-button
                    size="mini"
                    type="primary"
                    icon="el-icon-plus"
                    v-b-modal="'modal-drawing'"
                    @click="segue('new', node, data, $event)"
                    plain
                  ></el-button>
                  <el-button
                    size="mini"
                    type="info"
                    icon="el-icon-edit"
                    v-b-modal="'modal-level'"
                    @click="segue('edit', node, data, $event)"
                    plain
                  ></el-button>
                  <el-button
                    size="mini"
                    type="danger"
                    icon="el-icon-delete"
                    @click.stop="removeNode(node, data)"
                    plain
                  ></el-button>
                </span>
              </div>
              <div
                class="custom-tree-node"
                v-if="node.data.type == 'dwg'"
              >
                <span class="tree-item-drawing"><i class="el-icon-picture-outline"></i> REV {{ node.data.label }}</span>
                <span class="d-none d-sm-block">
                  <el-button
                    size="mini"
                    type="success"
                    icon="el-icon-download"
                    @click.stop="downloadDwg(node)"
                    plain
                  ></el-button>
                  <el-button
                    size="mini"
                    type="danger"
                    icon="el-icon-delete"
                    @click.stop="removeNode(node, data)"
                    plain
                  ></el-button>
                </span>
              </div>
            </span>

          </el-tree>
        </div>
      </b-col>
    </b-row>
    <modal-building
      @updateNode="updateNode"
      @appendNode="appendNode"
      :node="node"
      :mode="mode"
      :data="data"
    ></modal-building>
    <modal-level
      @updateNode="updateNode"
      @appendNode="appendNode"
      :node="node"
      :mode="mode"
      :data="data"
    />
    <modal-drawing
      @appendNode="appendNode"
      :node="node"
      :mode="mode"
      :data="data"
    ></modal-drawing>
    <modal-integrity></modal-integrity>
  </div>
</template>




<script>
import { mapMutations } from 'vuex';
import SideBarMenu from '../../components/SideBarMenu.vue';
import IdleTimer from '../../components/IdleTimer.vue';
import MobileDetected from '../../components/MobileDetected.vue';
import axiosResource from '@/utils/axiosResource';
import ModalBuilding from '@/components/Modals/Building/ModalBuilding.vue';
import ModalLevel from '@/components/Modals/Building/ModalLevel.vue';
import ModalDrawing from '@/components/Modals/Building/ModalDrawing.vue';
import ModalIntegrity from '@/components/Modals/ModalIntegrity.vue';
import swal from 'sweetalert2';
const swalBootsrap = swal.mixin({
  customClass: {
    confirmButton: 'btn btn-primary btn-swal',
    cancelButton: 'btn btn-light btn-swal',
  },
  buttonsStyling: false,
});

export default {
  components: {
    SideBarMenu,
    IdleTimer,
    MobileDetected,
    ModalBuilding,
    ModalLevel,
    ModalDrawing,
    ModalIntegrity,
  },
  data() {
    return {
      show: false,
      loading: false,
      filterText: '',
      projectUuid: null,
      props: {
        label: 'name',
        children: [],
        isLeaf: 'leaf',
      },
      rootNode: {},
      node: {},
      mode: '',
      data: {},
      bdgMode: '',
      bdgData: {},
      lvlMode: '',
      lvlData: {},
      qtt: '',
    };
  },
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val);
    },
  },
  methods: {
    filterNode(value, data) {
      if (!value) return true;
      return data.label.toLowerCase().indexOf(value) !== -1;
    },
    segue(mode, node, data, event) {
      // Handle node expand
      if (mode === 'new') {
        if (node.expanded == true) {
          event.stopPropagation();
        }
      } else if (mode === 'edit') {
        event.stopPropagation();
      }
      // Set segwe data
      this.node = node;
      this.mode = mode;
      this.data = data;

      console.log('------ SEGWE');
      console.log('node', node);

      if (node != '') {
        this.$refs.tree.store.setCurrentNode(node);
      } else {
        this.$refs.tree.setCurrentKey(null);
      }

      console.log(
        'this.$refs.tree.getCurrentNode()',
        this.$refs.tree.getCurrentNode()
      );
    },
    downloadDwg(node) {
      // Get presigned URL and create download

      axiosResource
        .get('/admin/buildings/drawing/presignedUrl/' + node.data.key)
        .then((res) => {
          console.log(res);
          const a = document.createElement('a');
          const url = res.data;
          a.href = url;
          a.download = url.split('/').pop();
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        })
        .catch((err) => {
          this.$globalSwal.error(err.response.data.msg);
        });
    },
    removeNode(node, data) {
      let type = data.type;
      let uuid = data.uuid;
      console.log(data);

      switch (type) {
        case 'bdg':
          type = 'building';
          break;
        case 'lvl':
          type = 'level';
          break;
        case 'dwg':
          type = 'drawing';
          break;
        default:
          break;
      }
      swalBootsrap
        .fire({
          title: this.$i18n.t('building.questionDelete'),
          icon: 'warning',
          html: this.$i18n.t('building.warningDelete'),
          showCancelButton: true,
          confirmButtonText: this.$i18n.t('action.confirm'),
          cancelButtonText: this.$i18n.t('action.cancel'),
        })
        .then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            this.deleteInDb(node, type, uuid);
          }
        });
    },
    deleteInDb(node, type, uuid) {
      this.$store.commit('loading', true);

      axiosResource
        .delete(`/admin/buildings/${type}/${uuid}`)
        .then((response) => {
          if (!response.data.success) return this.$globalSwal.error();

          this.$store.commit('loading', false);
          this.$message({
            message: this.$tc('building.deleted'),
            type: 'success',
          });
          this.$refs.tree.remove(node);
        })
        .catch((err) => {
          this.$store.commit('loading', false);
          this.$globalSwal.error(err.response.data.msg);
        });
    },
    ...mapMutations(['SAVE_CONTEXT']),
    handleNodeClick(data) {
      console.log(data);
    },
    updateNode(node, type, data) {
      console.log('updateNode fired');
      console.log(`type=${type}`);
      console.log('data', data);
      console.log('---- MY NODE COMING BACK');
      console.log('node', node);
      // Update node data
      node.data.label = data.name;
      node.data.ref = data.ref;
      // Force tree refresh
      // this.$refs.tree.updateKeyChildren(node.key, node.data.children);
      // this.$router.go(this.$router.currentRoute);
      this.$message({
        message: this.$tc('building.success.change'),
        type: 'success',
      });
    },
    appendNode(data) {
      console.log('--APPEDN NODE');
      console.log(data);

      const parent = this.$refs.tree.store.getCurrentNode();
      const clgStyle = 'color: red;';
      console.log('%c' + 'PARANT below', clgStyle);

      if (parent === undefined || parent === null) {
        console.log('%c' + 'ADD NEW BUILDING', clgStyle);
        const newBdgChild = {
          uuid: data.uuid,
          isIndex: 1,
          label: data.name,
          leaf: false,
          ref: data.ref,
          type: 'bdg',
        };
        this.$refs.tree.append(newBdgChild);
      } else {
        console.log('%c' + 'ADD TO PARENTs', clgStyle);
        console.log('parent', parent);
        let newChild;
        if (parent.level === 1) {
          newChild = {
            uuid: data.uuid,
            isIndex: 1,
            label: data.name,
            leaf: false,
            type: 'lvl',
          };
        } else if (parent.level === 2) {
          newChild = {
            uuid: data.uuid,
            label: data.name,
            key: data.key,
            leaf: true,
            type: 'dwg',
          };
        }
        console.log('newChild to append to tree', newChild);
        this.$refs.tree.append(newChild, parent);
      }

      this.$message({
        message: this.$tc('building.success.done'),
        type: 'success',
      });
    },

    // appendNode(data) {
    //   console.log('--APPEDN NODE');
    //   console.log(data);

    //   const parent = this.$refs.tree.store.getCurrentNode();
    //   const clgStyle = 'color: red;';
    //   console.log('%c' + 'PERANT below', clgStyle);

    //   if (parent === undefined || parent === null) {
    //     console.log('%c' + 'ADD NEW BUILDING', clgStyle);
    //     const newBdgChild = {
    //       id: data.id,
    //       isIndex: 1,
    //       label: data.name,
    //       leaf: false,
    //       ref: data.ref,
    //       type: 'bdg',
    //     };
    //     this.$refs.tree.append(newBdgChild);
    //   } else {
    //     console.log('%c' + 'ADD TO PARENTs', clgStyle);
    //     console.log('parent', parent);
    //     let newChild;
    //     if (parent.level === 1) {
    //       newChild = {
    //         id: data.id,
    //         isIndex: 1,
    //         label: data.name,
    //         leaf: false,
    //         type: 'lvl',
    //       };
    //     } else if (parent.level === 2) {
    //       newChild = {
    //         id: data.id,
    //         label: data.name,
    //         key: data.key,
    //         leaf: true,
    //         type: 'dwg',
    //       };
    //     }
    //     console.log('newChild to append to tree', newChild);
    //     this.$refs.tree.append(newChild, parent);
    //   }

    //   this.$message({
    //     message: this.$tc('building.success.done'),
    //     type: 'success',
    //   });
    // },
    loadNode(node, resolve) {
      // Primary Node Processing
      if (node.level === 0) {
        // this.rootNode = node;
        this.requestBuildings(resolve);
      }
      // Processing of the remaining nodes
      if (node.level === 1) {
        // Attention! Pass resolve to your own asynchrony
        this.getLevels(node, resolve);
      }
      if (node.level === 2) {
        // Attention! Pass resolve to your own asynchrony
        this.getDrawings(node, resolve);
      }
    },
    getDrawings(node, resolve) {
      let levelUuid = node.data.uuid;
      const clgStyle = 'color: red;';
      console.log('%c' + 'getDrawings', clgStyle);
      console.log(`for levelUuid= ${levelUuid}`);
      axiosResource
        .get('/admin/buildings/drawing/' + levelUuid)
        .then((res) => {
          console.log('getDrawings', res);
          res.data.forEach((et) => {
            et.leaf = true;
          });
          let data = res.data;
          console.log(data);

          resolve(data);
        })
        .catch((err) => {
          this.$globalSwal.error(err.response.data.msg);
        });
    },
    getLevels(node, resolve) {
      let buildingUuid = node.data.uuid;
      axiosResource
        .get('/admin/buildings/level/' + buildingUuid)
        .then((res) => {
          res.data.forEach((et) => {
            if (et.isIndex === '1') {
              et.leaf = true;
            } else {
              et.leaf = false;
            }
          });
          let data = res.data;
          resolve(data);
        })
        .catch((err) => {
          this.$globalSwal.error(err.response.data.msg);
        });
    },
    requestBuildings(resolve) {
      const clgStyle = 'color: red;';
      console.log('%c' + 'requestBuildings', clgStyle);
      this.loading = true;
      this.projectUuid = this.$store.getters.currentProject.uuid;
      axiosResource
        .get('/admin/buildings/building/' + this.projectUuid)
        .then((res) => {
          this.loading = false;
          this.qtt = res.data.length;
          res.data.forEach((et) => {
            if (et.isIndex === '1') {
              et.leaf = true;
            } else {
              et.leaf = false;
            }
          });
          let dataTree = res.data;
          console.log(dataTree);
          resolve(dataTree);
        })
        .catch((err) => {
          this.loading = false;
          if (err.response.data.message == 'error.noIntegrityToken') {
            this.$bvModal.show('modal-integrity');
          } else {
            this.$globalSwal.error(err.response.data.message);
          }
        });
    },
  },
  mounted() {
    this.show = !this.show;
    const { path } = this.$route;
    this.SAVE_CONTEXT(path);
  },
};
</script>