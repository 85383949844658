<template>
  <div>
    <side-bar-menu :menu="$section().admin"></side-bar-menu>
    <idle-timer></idle-timer>
    <mobile-detected></mobile-detected>
    <b-row class="top-row border-bottom d-md-flex align-items-center">
      <b-col
        class="d-none d-md-block"
        cols="2"
        md="2"
      >
        <span class="custom-font-size-1rem">{{ qttFamily }} {{ $tc('category.category', qttFamily).toUpperCase() }}</span>
      </b-col>
      <b-col
        cols="12"
        md="6"
      >
        <el-input
          v-model="filterText"
          clearable
          size="large"
          prefix-icon="el-icon-search"
          :placeholder="$t('components.typeToSearch')"
        />
      </b-col>
      <b-col
        cols="4"
        md="4"
      >
        <el-button
          type="primary"
          @click="openModal('new', 0)"
          class="float-right"
          v-b-modal="'modal-category'"
        >
          {{ $t('action.add') }}
        </el-button>
        <el-button
          @click="exportExcel()"
          class="float-right mr-3"
        >Download Excel</el-button>
      </b-col>
    </b-row>

    <b-row>
      <b-col
        cols="12"
        md="12"
        lg="12"
        class="mb-4 pb-4 border-bottom bg-white"
      >
        <div class="mt-4 ml-4 tree-container">
          <el-tree
            class="mb-4"
            v-loading="loading"
            :data="computedData"
            node-key="uuid"
            :expand-on-click-node="true"
            :filter-node-method="filterNode"
            accordion
            ref="tree"
            :props="defaultProps"
          >
            <template #default="{ node, data }">
              <div class="custom-tree-node">
                <span
                  :class="getNodeClass(node.level)"
                  :style="{ color: node.level === 1 ? data.color : '' }"
                >{{ data.label }}</span>
                <span class="button-container">
                  <el-button
                    v-if="node.level != 4"
                    class="d-none d-md-block"
                    size="mini"
                    type="primary"
                    icon="el-icon-plus"
                    plain
                    @click.native.stop="openModal('new', node.level, node)"
                  ></el-button>
                  <el-button
                    class="d-none d-md-block"
                    size="mini"
                    type="info"
                    icon="el-icon-edit"
                    plain
                    @click.native.stop="openModal('edit', node.level - 1, node)"
                  ></el-button>
                  <el-button
                    class="d-none d-md-block"
                    size="mini"
                    type="danger"
                    icon="el-icon-delete"
                    plain
                    @click.native.stop="handleDeleteCategory(node)"
                  ></el-button>
                </span>
              </div>
            </template>
          </el-tree>
        </div>
      </b-col>
    </b-row>
    <modal-integrity></modal-integrity>
    <modal-category
      :mode="modalData.mode"
      :type="modalData.type"
      :uuid="modalData.uuid"
      :label="modalData.label"
      :color="modalData.color"
    ></modal-category>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import axiosResource from '@/utils/axiosResource';
import SideBarMenu from '../../components/SideBarMenu.vue';
import IdleTimer from '../../components/IdleTimer.vue';
import MobileDetected from '@/components/MobileDetected.vue';
import ModalIntegrity from '@/components/Modals/ModalIntegrity.vue';
import ModalCategory from '@/components/Modals/ModalCategory.vue';
import swal from 'sweetalert2';
import { EventBus } from '@/eventBus'; // Import the event bus

const swalBootstrap = swal.mixin({
  customClass: {
    confirmButton: 'btn btn-primary btn-swal',
    cancelButton: 'btn btn-light btn-swal',
  },
  buttonsStyling: false,
});

export default {
  components: {
    SideBarMenu,
    IdleTimer,
    ModalIntegrity,
    ModalCategory,
    MobileDetected,
  },
  data() {
    return {
      show: false,
      // loading: false,
      modalData: {
        mode: '',
        type: '',
        uuid: '',
        label: '',
        color: '',
      },
      filterText: '',
      defaultProps: {
        children: 'children',
        label: 'label',
      },
    };
  },
  computed: {
    ...mapGetters(['categories', 'loading', 'qttFamily']),
    computedData() {
      return this.categories;
    },
  },
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val);
    },
  },
  methods: {
    ...mapActions([
      'fetchCategories',
      'deleteCategory',
      'updateCategory',
      'createCategory',
    ]),
    ...mapMutations(['SAVE_CONTEXT']),
    showModalIntegrity() {
      this.$bvModal.show('modal-integrity');
    },
    openModal(mode, level, node) {
      let uuid = node?.data?.uuid || '';
      let label = node?.data?.label || '';
      let color = node?.data?.color || '';
      let type;
      switch (level) {
        case 0:
          type = 'family';
          break;
        case 1:
          type = 'activity';
          break;
        case 2:
          type = 'specialty';
          break;
        case 3:
          type = 'remark';
          break;
        default:
          type = '';
      }
      this.modalData = { mode, type, uuid, label, color };
      this.$nextTick(() => {
        this.$bvModal.show('modal-category');
      });
    },
    handleUpdateCategory(data) {
      this.updateCategory(data)
        .then(() => {
          this.$bvModal.hide('modal-category');
        })
        .catch((err) => {
          console.log(err);
          this.$globalSwal.error('Error updating category');
        });
    },
    handleCreateCategory(data) {
      this.createCategory(data)
        .then(() => {
          this.$bvModal.hide('modal-category');
        })
        .catch((err) => {
          console.log(err);
          this.$globalSwal.error('Error creating category');
        });
    },
    notReady(e) {
      e.stopPropagation();
      swalBootstrap.fire({
        title: 'Oops',
        text: this.$t('error.notReady'),
        showConfirmButton: true,
        icon: 'info',
      });
    },
    filterNode(value, data) {
      if (!value) return true;
      return data.label.toLowerCase().includes(value.toLowerCase());
    },
    getNodeClass(level) {
      switch (level) {
        case 1:
          return 'family';
        case 2:
          return 'activity';
        case 3:
          return 'specialty';
        case 4:
          return 'remark';
        default:
          return '';
      }
    },
    handleDeleteCategory(node) {
      let type;
      switch (node.level) {
        case 1:
          type = 'family';
          break;
        case 2:
          type = 'activity';
          break;
        case 3:
          type = 'specialty';
          break;
        case 4:
          type = 'remark';
          break;
        default:
          type = '';
      }

      if (!this.isProjectOwner(node.byUserUuid)) {
        swalBootstrap.fire({
          title: this.$t('project.notProjectOwner'),
          text: this.$t('project.contactAdmin'),
          confirmButtonText: 'OK',
          icon: 'warning',
        });
        return;
      }

      this.deleteCategory({
        type: type,
        itemUuid: node.data.uuid,
        node: node,
      });
    },
    isProjectOwner(byUserUuid) {
      return true; // Example: Always returns true for demonstration
    },
    fetchData() {
      const projectUuid = this.$store.getters.currentProject.uuid;
      this.fetchCategories(projectUuid);
      this.$store.commit('loading', true);
    },
    exportExcel() {
      const projectUuid = this.$store.getters.currentProject.uuid;
      const url = `/admin/categories/exportExcel/${projectUuid}`;

      axiosResource({
        url: url,
        method: 'GET',
        responseType: 'blob', // Important for handling binary data (like files)
      })
        .then((response) => {
          // Create a new Blob object using the response data
          const blob = new Blob([response.data], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          });

          // Create a link element
          const link = document.createElement('a');

          // Set the download attribute with a filename
          link.href = window.URL.createObjectURL(blob);
          link.download = `categories_${projectUuid}.xlsx`;

          // Append the link to the body
          document.body.appendChild(link);

          // Programmatically click the link to trigger the download
          link.click();

          // Remove the link from the document
          document.body.removeChild(link);
        })
        .catch((err) => {
          this.loading = false;
          if (
            err.response &&
            err.response.data.message === 'error.noIntegrityToken'
          ) {
            this.$bvModal.show('modal-integrity');
          } else {
            this.$globalSwal.error(
              err.response ? err.response.data.message : 'An error occurred'
            );
          }
        });
    },
  },
  mounted() {
    this.show = !this.show;
    this.SAVE_CONTEXT(this.$route.path);
    // this.loading = true;
    this.$store.commit('loading', true);
  },
  created() {
    this.fetchData();

    EventBus.$on('show-integrity-modal', this.showModalIntegrity);
  },
  beforeDestroy() {
    // Clean up the event listener
    EventBus.$off('show-integrity-modal', this.showModalIntegrity);
  },
};
</script>

<style>
.title {
  font-size: 1rem;
}

.family {
  font-size: 1.2rem;
}

.activity {
  font-size: 1.1rem;
  font-weight: 400;
}

.specialty {
  font-size: 1rem;
  text-decoration: underline;
}

.remark {
  font-size: 1rem;
}

.tree-container {
  overflow-x: auto;
}

.custom-tree-node {
  display: flex;
  align-items: center;
}

.button-container {
  display: flex;
  gap: 5px;
}

.button-container .el-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
</style>
